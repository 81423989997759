import styles from "./button.module.scss";
import { motion } from "framer-motion";

const Button = ({ children, isOutlined }) => {
  return (
    <motion.button
      className={isOutlined ? styles.outline : styles.button}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
    >
      {/* <span>{icon}</span>
      {url ? <a href={url}>name</a> : { name }} */}
      {children}
    </motion.button>
  );
};

export default Button;
