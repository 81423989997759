import { stacks } from "../../assets/data.js";
import styles from "./skills.module.scss";
import HeaderText from "../HeaderText/HeaderText.jsx";
import { motion } from "framer-motion";

const skillStack = {
  initial: { opacity: 0 },
  final: {
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.4,
      staggerDirectin: 1,
    },
  },
};

const stackItem = {
  initial: { opacity: 0 },
  final: { opacity: 1 },
};

const Skills = () => {
  return (
    <section className={`wrapper ${styles.skills}`}>
      <HeaderText string="Skills" />
      <section className={styles.showcase}>
        <div>
          <p>
            I'm comfortable working alongside with other developers as well as
            UI designers who are more familiar with code but I appraciates a
            private workspace. I enjoy a collaboratiive environment (remote or
            onsite) where tasks as defined. I prefer re-inventing the wheels
            (starting from scratch) when necessary - helps me control unnessary
            codes and libraries in project - and trying out new technologies.
          </p>
          <p>Below are tech stacks i have experience with (currently)</p>

          <motion.section
            className={styles.stack_list}
            variants={skillStack}
            initial="initial"
            whileInView="final"
            viewport={{ once: true }}
          >
            {stacks.map((stack, idx) => (
              <motion.span
                className={styles.stack}
                key={idx}
                variants={stackItem}
              >
                {stack}
              </motion.span>
            ))}
          </motion.section>
        </div>
      </section>
    </section>
  );
};

export default Skills;
