import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import Skills from "./components/Skills";
import History from "./components/History";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);

const App = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <main className="App">
      <Navbar />
      <Intro />
      <Projects />
      <Skills />
      <History />
      {/* resent blog sectin */}
      <Contact />
      <Footer />
    </main>
  );
};

export default App;
