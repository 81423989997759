import styles from "./navbar.module.scss";
import NavLogo from "./NavLogo";
import NavToggle from "./NavToggle";
import NavMenu from "./NavMenu";
import { useState } from "react";

const Navbar = () => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <nav className={`wrapper ${styles.navigation}`}>
      <NavLogo />
      <NavMenu toggleState={isToggled} />
      <NavToggle toggled={isToggled} setToggled={setIsToggled} />
    </nav>
  );
};

export default Navbar;
