import styles from "./contact.module.scss";

const index = () => {
  return (
    <section className={`wrapper ${styles.contact}`} id="contact">
      <h2>Contact Me</h2>
      <p>
        Get in touch with me via{" "}
        <a href="mailto:simplyelvis@outlook.com">gmail</a> or{" "}
        <a href="https://www.twitter.com/trickthevick">@trickthevick</a>{" "}
        <a href="https://www.youtube.com/trickthevick">youTube</a>{" "}
        <a href="https://www.linkedin.com/in/elvisndubuisi">linkedIn</a> social
        media handles.
      </p>
    </section>
  );
};

export default index;
