import styles from "./navbar.module.scss";
import { motion } from "framer-motion";

const NavLogo = () => {
  return (
    <motion.a
      href="/"
      className={styles.navlogo}
      initial={{ opacity: 0.5, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, type: "spring", stiffness: "200" }}
    >
      <div className={styles.name}>
        <span>
          <span>V</span>
          <span>i</span>
          <span>c</span>
          <span>t</span>
          <span>o</span>
          <span>r</span>
        </span>
        <span> </span>
        <span>
          <span>E</span>
          <span>l</span>
          <span>v</span>
          <span>i</span>
          <span>s</span>
        </span>
      </div>
      <div className={styles.specialty}>
        <motion.p
          initial={{ opacity: 0, x: -100 }}
          transition={{ delay: 0.5 }}
          animate={{ opacity: 1, x: 0 }}
        >
          JavaScript Developer.
        </motion.p>
      </div>
    </motion.a>
  );
};

export default NavLogo;
