import { motion } from "framer-motion";

const head = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.3,
      staggerChildren: 0.1,
      // delayChildren: 0.1,
      staggerDirection: 1,
      // type: "spring",
      // stiffness: "200",
    },
  },
};

const span = {
  hidden: { opacity: 0, translateX: "100%" },
  show: {
    opacity: 1,
    translateX: "0%",
    transition: { duration: 0.2, type: "spring", stiffness: "200" },
  },
};

const HeaderText = ({ string }) => {
  return (
    <motion.h2
      className="section-header"
      variants={head}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
    >
      <span>
        {string.split("").map((char, idx) => (
          <motion.span variants={span} key={idx}>
            {char}
          </motion.span>
        ))}
      </span>
    </motion.h2>
  );
};

export default HeaderText;
