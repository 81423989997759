import styles from "./footer.module.scss";

const index = () => {
  return (
    <footer className={`wrapper ${styles.footer}`}>
      {/* <span className={styles.me}>ELV</span>

      <div>
        <a href="/">Null</a>
        <a href="/">Null</a>
      </div> */}
    </footer>
  );
};

export default index;
