import styles from "./history.module.scss";
import HistoryCard from "./HistoryCard";
import { histories } from "../../assets/data";
import HeaderText from "../HeaderText/HeaderText";

const History = () => {
  return (
    <section className={`wrapper ${styles.history}`}>
      <HeaderText string="history" />
      <section className={styles.content}>
        {histories.map((history, idx) => (
          <HistoryCard data={history} key={idx} />
        ))}
        {/* <div>
        </div> */}
      </section>
    </section>
  );
};

export default History;
