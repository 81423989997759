export const profile = "images/profile.jpg";

export const stacks = [
  "ReactJs",
  "NestJs",
  "Scss",
  "TailwindCss",
  "SvelteJs",
  "Material-UI",
  "Chakra UI",
  "Strapi.io",
  "Styled-Components",
  "Framer",
  "NodeJs",
  "GraphQl",
  "MongoDB",
  "Git",
  "GitHub",
];

export const histories = [
  {
    name: "ArewaBooks",
    location: "Kano, Nigeria",
    role: "Fullstack Developer - Contract/Freelance",
    duration: ["Aug 2022", "Aug-2022"],
    activities: [
      "Feature update to the Arewa backend code base",
      "Bug fixes on frontend display",
      "Migration from AWS",
      "API optimization",
    ],
  },
  {
    name: "2TOC Solutions Limited",
    location: "Abuja, Nigeria",
    role: "Assit. Technical Supervisor - Frontend Developer",
    duration: ["Nov 2021", "May 2022"],
    activities: [
      "Handled and managed National Commissions for Persons with Disabilities (NCPWD) website",
      "Handled Federal Fire Service (FFS) alert dashboard",
      "Desk Officer at NCPWD",
      "Lead presentation on NCPWD flag-off programme",
    ],
  },
  {
    name: "Federal Capital Territory Administration",
    location: "Abuja, Nigeria",
    role: "Web & IT instructor",
    duration: ["Nov 2020", "Oct 2021"],
    activities: [
      "1 year of loyal service under the National Youth Service Corps (NYSC) program",
      "IT assistant",
      "Web design and development instructor",
    ],
  },
  {
    name: "Aptech Computer Education",
    location: "Enugu, Nigeria",
    role: "Frontend Developer",
    duration: ["May 2018", "Match 2019"],
    activities: [
      "6 months internship + 4 months working as a developer",
      "collaborated and worked on small to medium sized projects with frontend teams",
    ],
  },
];

export const projects = [
    {
    title: "Barlink",
    stack: ["NestJs", "Typescript", "StyledComponent", "Framer"],
    description:
      "Create fully customizable QR codes with your brand colors and logo, product barcodes. Turn long boring iinks to short momizable links. Download generated contents in several printable formats and high-quality",
    liveLink: "barlink.vercel.app",
    // icon: "https://res.cloudinary.com/elvis-ike/image/upload/v1659089786/elvisndubuisi/brandIcon_veiori.svg",
    icon: "/public/favicon.png",
    tags: ["SaaS"],
  },
  {
    title: "QR Gen - QR code generator",
    stack: ["NestJs", "Typescript", "Tailwind CSS"],
    description:
      "Online SASS QR code generator - different download formats and QR customization features. Optimized for SEO, and secured.",
    liveLink: "qr-gen-client.vercel.app",
    // icon: "https://res.cloudinary.com/elvis-ike/image/upload/v1659089786/elvisndubuisi/brandIcon_veiori.svg",
    icon: "images/projects/favicon.png",
    tags: ["SaaS"],
  },
  {
    title: "African Heritage API",
    stack: ["NodeJs", "SvelteJs", "ExpressJs", "Redis", "JWT", "MongoDB"],
    description:
      "The African Heritage API is a free to use JSON API that delivers african proverbs/adages unique to different african countries.",
    liveLink: "africanheritage.vercel.app",
    // icon: "https://res.cloudinary.com/elvis-ike/image/upload/v1659089786/elvisndubuisi/brandIcon_veiori.svg",
    icon: "images/projects/favicon.png",
    tags: ["API", "Fullstack"],
  },
  {
    title: "Supa Stackbucks",
    stack: ["ReactJs", "React-router", "Leaflet", "Sass"],
    description:
      "Starbucks coffee website clone (frontend-only) with ReactJs. Includes page routing and map functinality",
    liveLink: "supa-starbucks.vercel.app",
    // icon: "https://res.cloudinary.com/elvis-ike/image/upload/v1659089419/elvisndubuisi/Starbucks-Logo_jitlrn.png",
    icon: "images/projects/Starbucks-Logo.png",
    tags: ["Frontend"],
  },
  {
    title: "Scripthouse",
    stack: ["NesxJs", "Strapi.io", "Chakra UI", "Postgresql", "Heroku"],
    description:
      "Fullstack personal blog web app build with Nextjs framework & strapi.io as CMS hosted on heroku. Integrates google analytics and adsence.",
    liveLink: "scripthouse.js.cool",
    // icon: "https://res.cloudinary.com/elvis-ike/image/upload/v1659089419/elvisndubuisi/Starbucks-Logo_jitlrn.png",
    icon: "",
    tags: ["Fullstack", "GraphQl", "Strapi"],
  },
];

export const miscProjects = [
  {
    title: "Sea War 2D",
    stack: ["Canvas", "JavaScript", "CSS"],
    description:
      "2D sidescroller HTML web game + CanvasAPI. No libraries/frameworks",
    liveLink: "seawar.netlify.app",
    icon: "",
    tags: ["Web", "2D Game"],
  },
  {
    title: "WikiSearch App",
    stack: ["HTML5", "JavaScript", "CSS"],
    description:
      "Wiki API search app with JavaScript Functional-programming approach in mind",
    liveLink: "wikisearch.web.app",
    icon: "images/projects/searchme.png",
    tags: ["Axios", "API"],
  },
];
