import styles from "./intro.module.scss";
import bigHead from "../../assets/images/bighead.svg";
import Button from "../Button/Button";
import resume from "../../assets/downloadable/Elvis-Ndubuisi-CV.pdf";

const index = () => {
  return (
    <section className={`wrapper ${styles.section}`}>
      <div>
        <a
          href="https://www.twitter.com/trickthevick"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        <a
          href="https://github.com/elvis-ndubuisi"
          target="_blank"
          rel="noreferrer"
        >
          Github
        </a>
        <a
          href="https://www.linkedin.com/in/elvisndubuisi"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        {/* Add youtube channel link */}
                <a
          href="https://www.youtube.com/trickthevick"
          target="_blank"
          rel="noreferrer"
        >
          Youtube
        </a>
      </div>
      <div>
        <img src={bigHead} alt="elvis victor ndubuisi" />
      </div>
      <div>
        <p>
          <span>H</span>ello! I'm <mark>Elvis</mark> - a self-taught{" "}
          <mark>JavaScript/TypeScript web developer</mark>, Tech
          enthusiast, and recently a Youtuber based in Nigeria, Africa.
        </p>
        <p>
          I specialize in building simplified and engaging (yet minimalistic)
          web applications with JavaScript frameworks and libraries and I have
          huge passion for Open-Source projects, game and AI programming, and
          Art. Looking to bring my skills to a tech company with global reach and contributing to the fast growing open-source communities.
        </p>

        <div className="rowflex">
          <Button isOutlined={true}>
            <a
              href="https://www.youtube.com/trickthevick"
              target="_blank"
              rel="noreferrer"
            >
              Youtube Channel
            </a>
          </Button>
          <Button>
            <a href={resume} download={true}>
              Download CV
            </a>
          </Button>
        </div>
      </div>
    </section>
  );
};

export default index;
