import styles from "./projects.module.scss";
import { motion } from "framer-motion";

const ProjectCard = ({ data }) => {
  return (
    <motion.div
      className={styles.project}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1, transition: { duration: 0.6 } }}
    >
      <div>
        {data.tags.map((tag, idx) => (
          <span key={idx}>{tag}</span>
        ))}
      </div>

      <div>
        <img src={data.icon} alt={data.title} className={styles.icon} />

        <h3>{data.title}</h3>

        <p className={styles.stack}>
          {data.stack.map((stack, idx) => (
            <span key={idx}>{stack}, </span>
          ))}
          {/* <span>Nodejs,</span> <span>ExpressJs,</span> <span>ReactJs</span> */}
        </p>

        <p className={styles.description}>{data.description}</p>
      </div>
      <a href={`https://${data.liveLink}`} target="_blank" rel="noreferrer">
        {data.liveLink} <span>--</span>
      </a>
    </motion.div>
  );
};

export default ProjectCard;
