import styles from "./projects.module.scss";
import ProjectCard from "./ProjectCard";
import { projects, miscProjects } from "../../assets/data";
import HeaderText from "../HeaderText/HeaderText.jsx";

const Projects = () => {
  return (
    <section className={`wrapper`}>
      <HeaderText string="projects" />
      <div className={styles.projects}>
        {projects.map((project, idx) => (
          <ProjectCard data={project} key={idx} />
        ))}
      </div>
      <div className={styles.misc_projects}>
        <h2>Games and Others</h2>

        <div>
          {miscProjects.map((mini, idx) => (
            <ProjectCard data={mini} key={idx} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
