import styles from "./navbar.module.scss";

const NavToggle = ({ toggled, setToggled }) => {
  return (
    <div className={styles.toggle} onClick={() => setToggled(!toggled)}>
      <span className={styles.bar}></span>
      <span className={styles.bar}></span>
      <span className={styles.bar}></span>
    </div>
  );
};

export default NavToggle;
