import styles from "./history.module.scss";
import { motion } from "framer-motion";

const HistoryCard = ({ data }) => {
  return (
    <motion.div
      className={styles.history_card}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1, transition: { duration: 0.6 } }}
    >
      <h4>
        {data.name} <span>{data.location}</span>
      </h4>
      <div>
        <p>{data.role}</p>

        <p>
          <span>{data.duration[0]}</span> / <span>{data.duration[1]}</span>
        </p>
      </div>
      <ul>
        {data.activities.map((activity, idx) => (
          <li key={idx}>{activity}</li>
        ))}
      </ul>
    </motion.div>
  );
};

export default HistoryCard;
