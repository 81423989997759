import styles from "./navbar.module.scss";
import resume from "../../assets/downloadable/Elvis-Ndubuisi-CV.pdf";
import Button from "../Button/Button";

const NavMenu = ({ toggleState }) => {
  return (
    <section
      className={`${styles.nav_menu} ${toggleState ? styles.active : ""}`}
    >
      <a href="#contact">Contact</a>
      <Button>
        <a href={resume} download="true">
          Download CV
        </a>
      </Button>
    </section>
  );
};

export default NavMenu;
